<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-gavel</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Bes. rechtliche Lage</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2 mt-4"
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        persistent-hint
        hint="Schüler*in oder Klasse"
        clearable
      ></v-text-field>
      <template v-slot:extension>
        <DivisionTabs align-with-title v-model="filter.division" all />
      </template>
    </v-app-bar>
    <v-toolbar flat class="mb-4">
      <v-select
        :items="status"
        label="Status"
        single-line
        hide-details=""
        v-model="filter.status"
        clearable
      ></v-select>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filtered"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem clickable :value="item.student" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import DivisionTabs from "@/components/DivisionTabs.vue";
import PersonItem from "@/components/PersonItem.vue";

import { comparePeople } from "common/utils/people.js";

export default {
  name: "SpecialLegalStatus",
  components: {
    DivisionTabs,
    PersonItem,
  },
  data() {
    return {
      filter: {
        division: {},
        search: "",
        status: "",
      },
      status: [],
      loading: false,
      headers: [
        { text: "Status", value: "legalStatus" },
        { text: "Klasse", value: "student.schoolClass.code" },
        { text: "Schüler*in", value: "student", sort: comparePeople },
      ],
      items: [],
    };
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        localStorage.setItem(
          "specialLegalStatusFilter",
          JSON.stringify(this.filter)
        );
      },
    },
  },
  async created() {
    if (localStorage.getItem("specialLegalStatusFilter")) {
      this.filter = JSON.parse(
        localStorage.getItem("specialLegalStatusFilter")
      );
    }
    this.loading = true;
    this.items = await this.apiList({
      resource: "student/speciallegalstatus",
    });

    this.items.forEach((item) => {
      if (!this.status.includes(item.legalStatus)) {
        this.status.push(item.legalStatus);
      }
    });
    this.status = this.status.sort();
    this.loading = false;
  },
  computed: {
    filtered() {
      if (!this.filter.division) {
        return this.items;
      }
      return this.items.filter(
        (item) =>
          (this.filter.division.id === 0 ||
            this.filter.division.id === item.student.division.id) &&
          (!this.filter.status || this.filter.status === item.legalStatus) &&
          (!this.filter.search ||
            item.student.firstName
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()) ||
            item.student.lastName
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()) ||
            item.student.schoolClass.code
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()))
      );
    },
  },
};
</script>
